import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import CarbonHelpers from './helpers'
import { CarbonResult } from 'graphql/types'
import { carbonResultDoc } from './graphql'

export type CarbonCaretoriesType = 'energy' | 'banking' | 'lifestyle' | 'transport' | 'super'

export type CarbonModel = {
    getResults: () => any
    results: CarbonResult[]
    helpers: CarbonHelpers
    seeLandingValue: (domain: string) => void
    seeDetailsValue: (domain: string) => void
    lastSeenNumbersDetails: EmissionNumberUpdateSet
    lastSeenNumbersLanding: EmissionNumberUpdateSet
    loading: boolean
}

export type EmissionNumberUpdate = {
    Current?: number
    New?: number
}
export type EmissionNumberUpdateSet = {
    [index: string]: EmissionNumberUpdate
}

export const useCarbonModel = (): CarbonModel => {
    const [lastSeenNumbersDetails, setLastSeenNumbersDetails] =
        React.useState<EmissionNumberUpdateSet>({})
    const [lastSeenNumbersLanding, setLastSeenNumbersLanding] =
        React.useState<EmissionNumberUpdateSet>({})

    const [fetchResults, result] = useLazyQuery(carbonResultDoc, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const newUpdateSetDetails = { ...lastSeenNumbersDetails }
            const newUpdateSetLanding = { ...lastSeenNumbersLanding }
            data.carbonResults.forEach((res: CarbonResult) => {
                if (res.Emissions && res.Emissions != '') {
                    if (!newUpdateSetDetails[res.Domain]) newUpdateSetDetails[res.Domain] = {}
                    if (!newUpdateSetLanding[res.Domain]) newUpdateSetLanding[res.Domain] = {}
                    newUpdateSetDetails[res.Domain].New = Number(res.Emissions)
                    newUpdateSetLanding[res.Domain].New = Number(res.Emissions)
                    if (!newUpdateSetDetails[res.Domain].Current)
                        newUpdateSetDetails[res.Domain].Current =
                            newUpdateSetDetails[res.Domain].New
                    if (!newUpdateSetLanding[res.Domain].Current)
                        newUpdateSetLanding[res.Domain].Current =
                            newUpdateSetLanding[res.Domain].New
                }
            })
            setLastSeenNumbersDetails(newUpdateSetDetails)
            setLastSeenNumbersLanding(newUpdateSetLanding)
            // console.log(newUpdateSetLanding)
        }
    })


    const results = result.data?.carbonResults ?? []
  

    const loading = result.loading
    const seeLandingValue = (domain: string) => {
        const newUpdateSetLanding = { ...lastSeenNumbersLanding }
        newUpdateSetLanding[domain].Current = newUpdateSetLanding[domain].New
        setLastSeenNumbersLanding(newUpdateSetLanding)
    }
    const seeDetailsValue = (domain: string) => {
        const newUpdateSetDetails = { ...lastSeenNumbersDetails }
        newUpdateSetDetails[domain].Current = newUpdateSetDetails[domain].New
        setLastSeenNumbersLanding(newUpdateSetDetails)
    }

    const getResults = () => fetchResults()

    return {
        loading,
        lastSeenNumbersDetails,
        lastSeenNumbersLanding,
        getResults,
        results,
        helpers: new CarbonHelpers(results),
        seeLandingValue,
        seeDetailsValue,
    }
}
