import * as React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
} from '@chakra-ui/react'
import { AppContext } from 'model'
import { NotificationItem } from 'graphql/types'

type Props = {
    item: NotificationItem
}
const NotificationItemRow = ({ item }: Props) => {
    const app = React.useContext(AppContext)

    return (
        <Card>
            <CardHeader>Notifications</CardHeader>
            <CardBody>
                { item.Icon }
                { item.Title }
                { item.Date }
                { item.Content }
               
            </CardBody>
        </Card>
    )
}

export default NotificationItemRow
