import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
    Card,
    CardHeader,
    CardBody,
    GridItem,
    Grid,
} from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { HeroCard } from 'components/common/HeroCard'
import HomeCard from 'components/homecard'
import { AppContext } from 'model'
import YouVsOthers from 'components/you-vs-others'
import { commas } from 'utils/helpers'

const OurImpact = () => {
    const app = React.useContext(AppContext)

    const testImpact = {
        AverageEmissions: 200,
        CarbonSaved: 52783,
        TotalSavedFromSwitching: 30928
    }
    const impact = testImpact
    // const impact = app.partner.Impact

    return (
        <Box>
            <Card>
                <CardHeader>Our Impact</CardHeader>
                <CardBody>
                    <Grid
                        templateRows='repeat(1, 1fr)'
                        templateColumns='repeat(3, 1fr)'
                        gap={4}
                    >
                        <GridItem style={{borderRight: "1px solid grey", paddingRight: "18px"}}>
                            <Text size="20px">{ impact.AverageEmissions } Co2e / Yr</Text>
                            <Text>Average Carbon Emissions</Text>
                        </GridItem>
                        <GridItem style={{borderRight: "1px solid grey", paddingRight: "18px"}}>
                            <Text size="20px">{ commas(impact.CarbonSaved) }</Text>
                            <Text>Tonnes of carbon saved</Text>
                        </GridItem>
                        <GridItem>
                            <Text size="20px">${ commas(impact.TotalSavedFromSwitching) }</Text>
                            <Text>Total savings from switching</Text>
                        </GridItem>
                    </Grid>
                    
                </CardBody>
            </Card>
            
        </Box>
    )
}

export default OurImpact
