import { Button, Input, Spinner } from '@chakra-ui/react'
import { AppContext } from 'model'
import * as React from 'react'
import { isValidHttpUrl } from './util'
import { useMutation } from '@apollo/client'
import testOidcEndpoint from 'graphql/mutations/testOidcEndpoint'

type Props = {
    oidcEndpoint: string
    setOidcEndpoint: (value:string) => void
    oidcTestToken: string
    setOidcTestToken: (value:string) => void
}

const OidcConfig = ({oidcEndpoint, setOidcEndpoint, oidcTestToken, setOidcTestToken}: Props) => {
    const app = React.useContext(AppContext)
    const [testToken, process] = useMutation(testOidcEndpoint)

    const testOidcToken = async () => {
        await testToken({
            variables: {
                Token: oidcTestToken, 
                Endpoint: oidcEndpoint,
            }
        })
    }
    return (
        <div>
            <div>
                <div>
                    <label>OIDC JWKS endpoint</label>
                    <div>Note: this process assumes the JWKS endpoint is publically accessible and the Acacia servers will be able to fetch a JWKS JWT assertion without any prior authentication</div>
                    <div>Is there are special requirements or processes required for the accessing of the IDP JWKS certificate please contact Acacia IT support at support@acaciamoney.com</div>
                    <Input 
                        value={oidcEndpoint} 
                        onChange={(event) => setOidcEndpoint(event.target.value)}
                        type='text'
                        isInvalid={!isValidHttpUrl(oidcEndpoint)}
                    />
                </div>
                { oidcEndpoint != "" && isValidHttpUrl(oidcEndpoint) && (
                    <div>
                        <label>Test token</label>
                        <div>Once you have input your valid JWKS URL you can test the configuration with the field below.</div>
                        <div>Generate an OIDC compliant token from your IDP and input the token into the field below. Click the test button and the Acacia API system will confirm that it is able to properly validate the token.</div>
                        <Input 
                            value={oidcTestToken} 
                            onChange={(event) => setOidcTestToken(event.target.value)}
                            type='text'
                        />
                        { process.loading 
                            ? <Spinner />
                            : <Button onClick={() => testOidcToken()} isDisabled={!isValidHttpUrl(oidcEndpoint) || oidcTestToken == ""}>TEST</Button>
                        }
                        { process.called && !process.loading && (
                            <div>
                            {
                                process.data.testOidcEndpoint.FailureReason != "" ? 
                                process.data.testOidcEndpoint.FailureReason : 
                                process.data.testOidcEndpoint.Status
                            }
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default OidcConfig
