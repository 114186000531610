// Original src https://auth0.com/docs/quickstart/spa/react/01-login#create-a-sample-application
import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, {
    IdToken,
    PopupLoginOptions,
    RedirectLoginResult,
    getIdTokenClaimsOptions,
    RedirectLoginOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    LogoutOptions,
    Auth0ClientOptions,
} from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'

export interface User {
    picture?: string
    name?: string
    email?: string
    partnerID: string
}

interface Auth0Context {
    isAuthenticated: boolean
    user: User | undefined
    loading: boolean
    token: IdToken | undefined
    popupOpen: boolean
    loginWithPopup(options: PopupLoginOptions): Promise<void>
    handleRedirectCallback(): Promise<RedirectLoginResult>
    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
    loginWithRedirect(o: RedirectLoginOptions): Promise<void>
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
    getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
    logout(o?: LogoutOptions): void
}

interface Auth0ProviderOptions {
    children: React.ReactElement
    onRedirectCallback?(result: RedirectLoginResult): void
}

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<Auth0Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!
export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState<User>()
    const [token, setToken] = useState<IdToken>()
    const [auth0Client, setAuth0] = useState<Auth0Client>()
    const [loading, setLoading] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions)
            setAuth0(auth0FromHook)

            if (window.location.search.includes('code=')) {
                const { appState } = await auth0FromHook.handleRedirectCallback()
                onRedirectCallback(appState)
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated()

            setIsAuthenticated(isAuthenticated)

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser()
                const idToken = await auth0FromHook.getIdTokenClaims()
                user.isAdmin = true
                user.partnerID = "lush"
                setUser(user)
                setToken(idToken)
            }

            setLoading(false)
        }
        initAuth0()
        // eslint-disable-next-line
    }, [])

    const loginWithPopup = async (o: PopupLoginOptions) => {
        setPopupOpen(true)
        try {
            await auth0Client!.loginWithPopup(o)
        } catch (error) {
            console.error(error)
        } finally {
            setPopupOpen(false)
        }
        const user = await auth0Client!.getUser()
        const idToken = await auth0Client!.getIdTokenClaims()
        console.log(idToken)
        setUser(user)
        setToken(idToken)
        setIsAuthenticated(true)
    }

    const handleRedirectCallback = async () => {
        setLoading(true)
        const result = await auth0Client!.handleRedirectCallback()
        const user = await auth0Client!.getUser()
        const idToken = await auth0Client!.getIdTokenClaims()
        console.log(idToken)
        setLoading(false)
        setIsAuthenticated(true)
        setToken(idToken)
        setUser(user)
        return result
    }
    return (
        <Auth0Context.Provider
            value={{
                token,
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
                    auth0Client!.getIdTokenClaims(o),
                loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
                getTokenSilently: (o: GetTokenSilentlyOptions | undefined) =>
                    auth0Client!.getTokenSilently(o),
                getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) =>
                    auth0Client!.getTokenWithPopup(o),
                logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
            }}
        >
            {children}
        </Auth0Context.Provider>
    )
}
