import { gql } from '@apollo/client'

export const PartnerDoc = gql`
    query partner {
        partner {
            Partner {
                ID           
                Name                  
                Logo  
                Sheet
                ModuleConfig {
                    Key
                    Value
                    DateUpdated
                    UpdatedBy
                }
                AdminConfig {
                    ApiAuthenticationMethod
                    OidcUrl
                    ApiKeys {
                        KeyID
                        DateCreated
                        DateLastUsed
                        CreatedBy
                    }
                }       
            }
            Users {           
                email            
                created          
                firstName        
                lastLogin        
                loginCount       
                partnerID        
                partnerGroupID   
            }
            Groups {
                ID           
                Name         
                Type         
            }
            IsAdmin
            User {
                email            
                created          
                firstName        
                lastLogin        
                loginCount       
                partnerID        
                partnerGroupID   
            }
           
        }
    }
`