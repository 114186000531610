import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Admin from 'pages/admin'
import KnowledgeBaseDashboard from './dashboard'
import Article from './article'
import Category from './category'

const Routes = () => (
    <Switch>
        <Route path="/knowledgebase" exact component={KnowledgeBaseDashboard} />
        <Route path="/knowledgebase/article/:id" component={Article} />
        <Route path="/knowledgebase/category/:id" component={Category} />
    </Switch>
)

export default Routes
