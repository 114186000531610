import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { AppContext } from "model"
import React, { useContext } from "react"

const Users = () => {
    const app = useContext(AppContext)
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Email</Th>
                    <Th>Created Date</Th>
                    <Th>Last Login</Th>
                    <Th>Login Count</Th>
                </Tr>
            </Thead>
            <Tbody>
            { app.partner.Users.map(user => (
                <Tr>
                    <Td>{user.email}</Td>
                    <Td>{user.created}</Td>
                    <Td>{user.lastLogin}</Td>
                    <Td>{user.loginCount}</Td>
                </Tr>
            ))}
             </Tbody>
        </Table>
    )
}
export default Users