import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Widgets from 'components/widgets'
import Dashboard from 'pages/dashboard'
import Admin from 'pages/admin'
import KnowledgeBase from 'pages/knowledgebase'

const Routes = () => (
    <Switch>
        <Route path="/" default exact component={Dashboard} />
        <Route path="/widgets" component={Widgets} />
        <Route path="/admin" component={Admin} />
        <Route path="/knowledgebase" component={KnowledgeBase} />
    </Switch>
)

export default Routes
