// @ts-nocheck
import React from 'react'
import { ApolloProvider, InMemoryCache, ApolloClient, from, createHttpLink, ApolloLink } from '@apollo/client'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { useAuth0 } from './components/auth/react-auth0-wrapper'
import appSyncConfig from './config/aws-exports'
import LoginScreen from './components/auth/LoginScreen'
import Spinner from 'components/common/Spinner'
import { IdToken } from '@auth0/auth0-spa-js'
import App from './App'
import ContentfulLink from 'utils/contentful/ContentfulApi'

export type ClaimsType = {
    'http://acacia.com/orgadmin'?: string
}

export const AuthContext = React.createContext<AuthContextType>({})

function Auth() {
    const { user, getIdTokenClaims, getTokenSilently, loading, isAuthenticated, token, logout } =
        useAuth0()
    React.useMemo(async () => {
        if (!loading && !isAuthenticated) {
            const t = await getTokenSilently()
        }
    }, [loading])
    if (loading) {
        return <Spinner />
    }

    // AppSync Config with Authentication
    const url = appSyncConfig.graphqlEndpoint
    const region = appSyncConfig.region

    const auth: AuthOptions = {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: async (): Promise<string> => {
            const token = (await getIdTokenClaims()) || ''
            return token.__raw
        },
    }

    // ApolloClient with AppSync Config
    const httpLink = createHttpLink({ uri: url })
    const contentfulLink = ContentfulLink()
    const link = from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink(url, httpLink),
    ])

    // Required for type resolution

    const apolloClient =  new ApolloClient({
        link: ApolloLink.split(
            (operation) => operation.getContext().clientName === 'contentful',
            contentfulLink,
            link
        ),
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })
    if (isAuthenticated) {
        return (
            <AuthContext.Provider value={{ token: token }}>
                <ApolloProvider client={apolloClient}>
                    <App />
                </ApolloProvider>
            </AuthContext.Provider>
        )
    } else return <LoginScreen />
}

export default Auth
