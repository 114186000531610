import * as React from 'react'
import b from 'bss'
// import { useNavigation } from '@react-navigation/native'
// import { LinearGradient } from 'expo-linear-gradient'

// import { useTheme } from '@theme'
import * as styles from './styles'

// import p from '@elements/core/text/base'
// import APressable from '@elements/core/button/aPressable'

// import { CarbonModelContext } from '@utils/models/carbon'
// import { HelpIcon, ProfleIconInCircle } from '@elements/core/icons'

const ProfleIconInCircle = () => {
    // used for cs hero
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
            <ellipse cx="5.5" cy="8.5" rx="3.5" ry="1.5" fill="white" />
            <circle cx="5.5" cy="4.5" r="1.5" fill="white" />
            <circle cx="5.5" cy="5.5" r="5" stroke="#333333" />
        </svg>
    )
}

export default function YouVsOthers({ emissions }: {emissions: number}) {
    // const { colors } = useTheme()
    // const styles = getStyles(colors)
    // const navigation = useNavigation()

    // const carbonModel = React.useContext(CarbonModelContext)
    // const blended = carbonModel.helpers.getBlendedEmissions()
    // const finished = carbonModel.helpers.hasAddedAllCategories()

    // const [elementWidth, setElementWidth] = React.useState(0)
    // Gets Height of Text
    // const getElementWidth = (layout: LayoutRectangle) => {
    //     const { width } = layout
    //     setElementWidth(width)
    // }

    // const yourEmissions = 25
    const [elementWidth, setWidth] = React.useState(0)
    const [xOffset, setXOffset] = React.useState(0)
    const elementRef = React.useRef<any>(null)

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(elementRef.current.offsetWidth)
            setXOffset(elementRef.current.offsetLeft)
        }
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const typicalAussie = 36
    const startOnTrack = 13
    const endOnTrack = 0

    const markers = 40
    const split = elementWidth / markers
    const placeTypicalAussie = split * typicalAussie - xOffset
    const placeStartOnTrack = split * startOnTrack - xOffset
    const placeEndOnTrack = split * endOnTrack - xOffset

    console.log({
        elementWidth,
        xOffset,
        split,
        placeStartOnTrack,
        placeEndOnTrack,
        placeTypicalAussie,
    })

    // handle if number is beyond graph range, e.g. above 40 or below 0
    const placeYou =
        (emissions > 40 ? elementWidth : emissions <= 0 ? 0 : split * emissions) -
        xOffset

    const hideExtraInYouPill = emissions >= 40 || emissions <= 1
    const youPillSize = hideExtraInYouPill ? 36 : 58

    const getPillColor = () => {
        if (emissions >= 40) return '#FF8211' // red
        if (emissions < 40 && emissions > startOnTrack) return '#FCBD1A' // orange
        if (emissions <= startOnTrack && emissions > placeEndOnTrack) return '#46C252' // green
        if (emissions == 0 || emissions < 0) return '#01B7F8' // blue
        return 'white'
    }
    const yourEmissionsPillColor = getPillColor()

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p className={styles.title}>Your Carbon</p>
                {/* {finished ? null : (
                    <APressable
                        onPress={() =>
                            navigation.navigate('Help Modal', {
                                tag: 'Carbon:Help:FootprintDefinitions',
                            })
                        }
                        hitSlop={0}
                    >
                        <div className={styles.type}>
                            <p className={styles.typeText} >
                                Blended
                            </p>
                            <HelpIcon withSurrond={false} />
                        </div>
                    </APressable>
                )} */}
            </div>

            <div className={styles.main}>
                <p className={styles.number}>{emissions.toFixed(2)}</p>
                <div className={styles.description}>
                    <p className={styles.descriptionText}>tonnes</p>
                    <div
                        className={
                            b`
                                width: 20;
                                height: 8;
                                background-color: #FCBD1A;
                                border-radius: 99;
                                margin-left: 8;
                                background-color: ${yourEmissionsPillColor}
                            `.class
                        }
                    />
                </div>
            </div>
            <p
                className={
                    b`
                    font-size: 12;
                    color: #939393;
                    padding-top: 2;
                    padding-bottom: ${emissions > 30 ? 6 : 0};
                `.class
                }
            >
                CO₂e
            </p>

            <div>
                {/* positions bottom of you pole on graph */}
                <div
                    style={{
                        left: elementWidth - placeYou - youPillSize / 2, // minus width of the pill
                        position: 'absolute',
                        zIndex: 99,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        className={
                            b`
                            background-color: black;
                            border-radius: 99;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 27;
                            justify-content: center;
                            z-index: 99;
                            width: ${youPillSize};
                        `.class
                        }
                    >
                        {hideExtraInYouPill ? null : (
                            <div style={{ paddingRight: 6 }}>
                                <ProfleIconInCircle />
                            </div>
                        )}
                        <p className={styles.youText}>You</p>
                    </div>
                    <div className={styles.youPole} />
                </div>
                <div style={{ height: 26.5 }} />
                <div className={styles.markers}>
                    <p
                        className={
                            b`
                            font-size: 12;
                            color: #939393;
                            width: 12;
                            padding-left: ${hideExtraInYouPill ? 2 : 0};
                        `.class
                        }
                    >
                        40t+
                    </p>
                    {/* <div className={styles.innerMarkers}> */}
                    <p className={styles.marker}>30t</p>
                    <p className={styles.marker}>20t</p>
                    <p className={styles.marker}>10t</p>
                    {/* </div> */}
                    <p
                        className={
                            b`
                            font-size: 12;
                            color: #939393;
                            text-align: right,
                            padding-right: ${hideExtraInYouPill ? 2 : 0};
                        `.class
                        }
                    >
                        0t
                    </p>
                </div>
                <div className={styles.gradient} ref={elementRef} />
                {/* <LinearGradient
                    colors={['#FF8211', '#FCBD1A', '#46C252', '#01B7F8']}
                    style={styles.gradient}
                    start={[0, 1]}
                    end={[1, 0]}
                    // onLayout={(event) => getElementWidth(event.nativeEvent.layout)}
                /> */}
                <div>
                    <div className={styles.poles}>
                        {/* position typical aussie pole */}
                        <div
                            style={{
                                position: 'absolute',
                                left: elementWidth - placeTypicalAussie,
                            }}
                        >
                            <div className={styles.pole} />
                        </div>
                        {/* position pole on left side range */}
                        <div
                            style={{
                                position: 'absolute',
                                left: elementWidth - placeStartOnTrack - 2,
                            }}
                        >
                            <div className={styles.shortPole} />
                        </div>
                        {/* position pole on right side range */}
                        <div
                            style={{
                                position: 'absolute',
                                left: elementWidth - placeEndOnTrack - 2,
                            }}
                        >
                            {/*  take off width of every element to its left */}
                            <div className={styles.shortPole} />
                        </div>
                        {/* horizontal join between two poles for on track */}
                        <div
                            style={{
                                position: 'absolute',
                                left: elementWidth - (placeStartOnTrack + placeEndOnTrack) / 2,
                                marginTop: 6,
                            }}
                        >
                            <div className={styles.shortPole} />
                        </div>
                        {/* position pole in center of horizontal range */}
                        <div
                            style={{
                                // due to rounding + width of every element to its left in the pole row
                                position: 'absolute',
                                left: elementWidth - placeStartOnTrack - 2,
                                width: placeStartOnTrack - placeEndOnTrack + 1,
                                height: 1,
                                backgroundColor: '#EBEBEB',
                                marginTop: 6,
                            }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <div className={styles.dot} />
                        {/* <p className={styles.labelText}>仏の人</p> */}
                        <p className={styles.labelText}>Typical Aussie</p>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            left: elementWidth - (placeStartOnTrack + placeEndOnTrack) / 2 - 49 - 4, // 57 is the width of the pill itself, 4 is the elements width to the left
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className={styles.label}>
                            <div
                                // colors={['#46C252', '#01B7F8']}
                                className={styles.dot}
                                // start={[0, 0]}
                                // end={[1, 1]}
                            />
                            <p className={styles.labelText}>On Track for Zero</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
