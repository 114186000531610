import { createHttpLink } from '@apollo/client'

export default function ContentfulLink() {
    // AppSync Config with Authentication

    const accessToken = 'gpmbS3UUBc6Qhr0WD3t-NI1aatzNsISch7fBjl6TFHw'
    // "nL-xXqoa9m8VH5nSI-JHbdb8JyDMb2o6Xu37VCi28HM"
    const url =
        'https://graphql.contentful.com/content/v1/spaces/335e88tfkbic?access_token=' + accessToken

    // gpmbS3UUBc6Qhr0WD3t-NI1aatzNsISch7fBjl6TFHw

    // ApolloClient with AppSync Config
    const httpLink = createHttpLink({ uri: url })
    return httpLink
}
