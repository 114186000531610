import { Avatar, Box, Button, HStack } from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { HeroCard } from 'components/common/HeroCard'
import { AppContext } from 'model'
import * as React from 'react'
import { Link } from 'react-router-dom'

type Props = {
    children: Node
}

const Header = ({ children}: Props) => {
    const { logout } = useAuth0()
    const app = React.useContext(AppContext)
    return (
        <Box
            width="100vw"
            height="100vh"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage="https://s3.ap-southeast-2.amazonaws.com/assets2.acaciamoney.com/mountain.jpg"
        >
            <HStack justifyContent="space-between" p={["1vh 2vw 1vh 2vw","1vh 2vw 1vh 2vw","5vh 5vw 1vh 5vw"]}>
                <Link to={'/'}>
                    <HStack>
                        <Avatar
                            size="sm"
                            borderRadius="0"
                            width="auto"
                            src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoWhite.png"
                        />
                        { app.partner.Partner && app.partner.Partner.Logo ? (
                            <div style={{marginLeft: "30px"}}>
                                <Avatar
                                    size="lg"
                                    borderRadius="0"
                                    width="auto"
                                    src={app.partner.Partner.Logo}
                                />
                            </div>
                        ) : <div>{app.partner.Partner.Name}</div>}
                    </HStack>
                </Link>
                <HStack gap="4">
                    <Link to={'/'}>
                        <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                            Home
                        </Button>
                    </Link>
                    <Link to={'/profile'}>
                        <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                            Profile
                        </Button>
                    </Link>
                    <Link to={'/knowledgebase'}>
                        <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                            Knowledge Base
                        </Button>
                    </Link>
                    { app.partner.IsAdmin == "true" && (
                        <Link to={'/admin'}>
                            <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                                Admin
                            </Button>
                        </Link>
                    )}
                    <Button onClick={() => logout()} variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                        Logout
                    </Button>
                    <HeroCard
                        mainText={app.user.name}
                        imageUrl={app.user.picture}
                        secondaryText={app.user.email}
                    />
                </HStack>
            </HStack>
            {children}
        </Box>
    )
}

export default Header
