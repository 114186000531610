import * as React from 'react'
import { Link } from 'react-router-dom'

const AdminToolbar = () => {
    return (
        <div style={{margin: "10px 15px", padding: "10px 15px", border: "1px solid #333", overflow: 'auto'}}>
            <div style={{margin: "2px 15px", padding: "10px 15px", border: "1px solid #333", float:"left"}}>
                <Link to={`/admin`}>Dashboard</Link>
            </div>
            <div style={{margin: "2px 15px", padding: "10px 15px", border: "1px solid #333", float:"left"}}>
                <Link to={`/admin/users`}>Users</Link>
            </div>
            <div style={{margin: "2px 15px", padding: "10px 15px", border: "1px solid #333", float:"left"}}>
                <Link to={`/admin/groups`}>Groups</Link>
            </div>
            <div style={{margin: "2px 15px", padding: "10px 15px", border: "1px solid #333", float:"left"}}>
                <Link to={`/admin/config`}>API Config</Link>
            </div>
            <div style={{margin: "2px 15px", padding: "10px 15px", border: "1px solid #333", float:"left"}}>
                <Link to={`/admin/docs`}>API Documentation</Link>
            </div>
        </div>
    )
}

export default AdminToolbar