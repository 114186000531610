import { useQuery } from "@apollo/client"
import { useAuth0, User as Auth0User } from "components/auth/react-auth0-wrapper"
import { UserGroup, User, UserUserGroup, NotificationItem, PartnerResponse } from "graphql/types"
import React from "react"
import { ContentFactory, useContentfulText } from "utils/contentful/useContentfulText"
import { CarbonModel, useCarbonModel } from "./carbon"
import { PartnerDoc } from "./graphql"

export const AppContext = React.createContext<AppModel>({
    partnerID: "", 
    partner: null,
    contentful: null, 
    carbon: null
})

export type AppModel = {
    partnerID?: string
    user?: Auth0User
    partner?: PartnerResponse | null
    contentful: ContentFactory
    carbon: CarbonModel
    error?: string
}

export const useAppModel = (): AppModel => {    
    const { user } = useAuth0()
    const result = useQuery(PartnerDoc)
    const contentful = useContentfulText()
    const carbonModel = useCarbonModel()

    if (result.error) {
        return {
            user: user,
            error: result.error.message,
            contentful, 
            carbon: carbonModel,
        }
    }
    const partnerResponse = !result.loading && result.data.partner as PartnerResponse

    return {
        partner: partnerResponse,
        user: user,
        partnerID: "lush",
        contentful, 
        carbon: carbonModel,
    }
}
