import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Box,
    Center,
} from '@chakra-ui/react'
import App from 'App'
import { AppContext } from 'model'
import { categories } from './tiles'
import { Link } from 'react-router-dom'

const KnowledgeBaseDashboard = () => {
    const app = React.useContext(AppContext)
    const allArticles = app.contentful.all
    if (!allArticles) return <></>

    return (
        <Box>
            <Center>
                <div style={{padding: "10px 6px", backgroundColor: "white", border: "1px solid #ccc",width: "70%", display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                    { categories.map(a => {
                        
                        return (
                            <Link to={`/knowledgebase/category/${a.title}`}>
                                <div style={{
                                    backgroundImage: `url(${a.image})`, 
                                    backgroundSize: "cover",
                                    borderRadius: "4px",
                                    border: "1px solid #333",
                                    padding: "10px 6px",
                                    margin: "10px 6px",
                                    height: "140px",
                                    width: "80%",
                                    textAlign: "end",
                                    fontWeight: "bold",
                                    WebkitTextStrokeWidth: "1px",
                                    WebkitTextStrokeColor: "#333",
                                    color: "white",
                                    fontSize: "23px"
                                }}>
                                    {a.title}
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </Center>
        </Box>
    )
}



export default KnowledgeBaseDashboard
