import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Admin from 'pages/admin'
import AdminUsers from './users'
import AdminGroups from './groups'
import AdminDashboard from './dashboard'
import AdminConfig from './config'
import AdminDocs from './docs'

const AdminRoutes = () => (
    <Switch>
        <Route path="/admin" exact component={AdminDashboard} />
        <Route path="/admin/users" component={AdminUsers} />
        <Route path="/admin/groups" component={AdminGroups} />
        <Route path="/admin/config" component={AdminConfig} />
        <Route path="/admin/docs" component={AdminDocs} />
    </Switch>
)

export default AdminRoutes
