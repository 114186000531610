import * as React from 'react'


const NotPartnerError = () => {
    return (
        <div>
            <div>
                It looks like the account you have logged in with is not linked to an active partner
            </div>
            <div>
                Please contact your partner admin or email <a href="email:support@acaciamoney.com">support@acaciamoney.com</a> for extra assistance
            </div>
        </div>
    )
}

export default NotPartnerError