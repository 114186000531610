export const formatMonthDate = (date: Date): string => {
    return date.getUTCMonth() + ' - ' + date.getFullYear()
}
// Round to d dp, handle weird JS rounding using Number.EPSILON
export const safeRound = (n: number, d = 2): number => {
    return Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)
}


export function commas(number: string | number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}