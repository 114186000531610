import gql from 'graphql-tag'

export default gql`
    mutation testOidcEndpoint( $Endpoint: String!, $Token: String!) {
        testOidcEndpoint(
            Endpoint: $Endpoint,
            Token: $Token
        ) {
            Status
            FailureReason
        }
    }
`
