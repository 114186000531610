import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import Routes from './Routes'
import { Link } from 'react-router-dom'

const KnowledgeBase = () => {
    return (
        <Box>
            <Center>
                <div style={{backgroundColor: "white", border: "1px solid #ccc", borderRadius: "2px",width: "70%"}}>
                    <Link to="/knowledgebase"><div style={{padding: "10px 15px"}}>Knowledge Base</div></Link>
                    <Routes />
                </div>
            </Center>
        </Box>
    )
}



export default KnowledgeBase
