import { useQuery } from "@apollo/client"
import contentful from "graphql/queries/contentful"
import React, { ReactNode } from "react"
import { documentToReactComponents } from  '@contentful/rich-text-react-renderer';

export type ContentFactory = {
    loading: boolean
    all: Article[]
    GetRichText: (name: string, style?: any | undefined) => ReactNode
}

export type Article = {
    category: string
    content: {
        json: any
    }
    id: string
    title: string
}

export const useContentfulText = (): ContentFactory => {
    const result = useQuery(contentful, {
        // variables: {
        //     preview: getEnvironment() !== 'prod' && getEnvironment() !== 'internal',
        // },
        fetchPolicy: 'cache-first',
        context: {
            clientName: 'contentful',
        },
        onError: (err) => {
            console.log('[ERROR] - ' + err.message)
        },
    })

    if (result.loading)
        return {
            all: [],
            loading: true,
            GetRichText: () => '',
        }     

    const getRichText = (id: string, style?: any): ReactNode => {
        if (!result.data) return null
        const items = ((result.data as any).knowledgeBaseCollection.items as Article[])
        const i = items.filter((a: any) => a.id === id)
        if (!items.length) {
            console.log('UNABLE TO DETECT CONTENTFUL ITEM! - ', name)
            return null
        }
        console.log('Get richtext: ', items[0].title, items[0].content.json)
        return documentToReactComponents(items[0].content.json)
    }
   
    return {
        all: result.data.knowledgeBaseCollection.items as Article[],
        loading: false,
        GetRichText: getRichText,
    }
}
