import * as React from 'react'
import NotPartnerError from "./notPartner"

export const getErrorMessage = (message: string) => {
    if (message == "user is not in a partner") return <NotPartnerError /> 
    return (
        <div>
            {message}
        </div>
    )
}