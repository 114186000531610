import { Button, Input, Spinner } from '@chakra-ui/react'
import { AppContext } from 'model'
import * as React from 'react'

const ApiKeys = () => {
    const app = React.useContext(AppContext)

    const deleteKey = (keyID: string) => {

    }
    const createNewApiKey = () => {

    }
    return (
        <div>
            <div>
                <div>
                    <label>API Key authentication process</label>
                    <div>This process will require that API calls to the Acacia APIs are sent with a specific authentication header in additon to the normal PartnerID header</div>
                    <div>Keys may be created and removed from the interface below</div>
                    <div>Each partner is restricted to having only 2 active API keys at any time</div>
                    <div>For security reasons once when a new key is created the key material may only be view once upon creation. If the key material is lost it may not be recovered and therefore the key must be removed and recreated</div>
                </div>
                <div>
                    {app.partner.Partner.AdminConfig && app.partner.Partner.AdminConfig.ApiKeys.map(a => (
                        <div>
                            <p>API Key</p>
                            <p>Created On: {a.DateCreated}</p> 
                            <p>Created By: {a.CreatedBy}</p> 
                            <p>Last Used: {a.DateLastUsed}</p> 
                            <p><Button onClick={() => deleteKey(a.KeyID)}>Delete key</Button></p> 
                        </div>
                    ))}
                </div>
                <div>
                    <Button onClick={() => createNewApiKey()}>Generate new key</Button>
                </div>
            </div>
        </div>
    )
}

export default ApiKeys
