import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Box,
    Card,
    CardHeader,
    CardBody,
    List,
    ListItem,
} from '@chakra-ui/react'
import { AppContext } from 'model'
import NotificationItem from './item'

const Notifications = () => {
    //const app = React.useContext(AppContext)
    //const notifications = app.partner.notifications
    const notifications  = []
    return (
        <Box>
            <Card>
                <CardHeader>Notifications</CardHeader>
                <CardBody>
                    <List>
                        { notifications.map(a => {
                            return (
                                <ListItem>
                                    <NotificationItem item={a}/>
                                </ListItem>
                            )
                        })}
                    </List>
                </CardBody>
            </Card>
        </Box>
    )
}

export default Notifications
