import { gql } from '@apollo/client'

export const carbonResultDoc = gql`
    query carbonResults($domain: String) {
        carbonResults(domain: $domain) {
            UserID
            Domain
            Emissions
            Calculations
            ResultID
            SuperFundLevel
            ScopeOneTwo
            ScopeThree
        }
    }
`
