interface Area {
    [key: string]: string
}

interface AreaData {
    [key: string]: Area
}

// Need to data source here
export const AverageHouseholdKwh: AreaData = {
    Sydney: { 1: '777', 2: '1309', 3: '1590', 4: '1828', 5: '2252' },
    Brisbane: { 1: '853', 2: '1282', 3: '1542', 4: '1921', 5: '2201' },
    Adelaide: { 1: '730', 2: '1262', 3: '1543', 4: '1780', 5: '2205' },
    Melbourne: { 1: '738', 2: '1210', 3: '1269', 4: '1451', 5: '1838' },
    Canberra: { 1: '1090', 2: '1527', 3: '1931', 4: '2386', 5: '2538' },
    Hobart: { 1: '1501', 2: '2196', 3: '2369', 4: '2705', 5: '2889' },
}

// https://www.aer.gov.au/system/files/Residential%20energy%20consumption%20benchmarks%20-%209%20December%202020_0.pdf
export const AverageHouseholdGas: AreaData = {
    ACT: { 1: '14687', 2: '36870', 3: '36935', 4: '43558', 5: '45808' },
    TAS: { 1: '14687', 2: '36870', 3: '36935', 4: '43558', 5: '45808' },
    NSW: { 1: '9835', 2: '16945', 3: '19978', 4: '24160', 5: '28799' },
    QLD: { 1: '3678', 2: '6261', 3: '7649', 4: '10295', 5: '13014' },
    SA: { 1: '9445', 2: '14671', 3: '22321', 4: '22750', 5: '23379' },
    VIC: { 1: '30414', 2: '50462', 3: '50743', 4: '56798', 5: '71186' },
}
export type ValidAustralianShortStates = 'NSW' | 'QLD' | 'SA' | 'VIC' | 'TAS' | 'ACT' | 'NT' | 'WA'
export type ValidAustralianStates =
    | 'New South Wales'
    | 'Queensland'
    | 'South Australia'
    | 'Victoria'
    | 'Tasmania'
    | 'Australian Capital Territory'
// | 'Northern Territory'
// | 'Western Australia'

export const StateToCities: Record<ValidAustralianStates, string> = {
    'New South Wales': 'Sydney',
    Queensland: 'Brisbane',
    'South Australia': 'Adelaide',
    Victoria: 'Melbourne',
    Tasmania: 'Hobart',
    'Australian Capital Territory': 'Canberra',
    // 'Northern Territory': '',
    // 'Western Australia': '',
}

export type ThreeLetterStates = 'NSW' | 'QLD' | 'SA' | 'VIC' | 'TAS' | 'ACT' | 'NT' | 'WA'

export const StateToThreeLetter = (state: ValidAustralianStates): ThreeLetterStates => {
    const convert = {
        'New South Wales': 'NSW',
        Queensland: 'QLD',
        'South Australia': 'SA',
        Victoria: 'VIC',
        Tasmania: 'TAS',
        'Australian Capital Territory': 'ACT',
        'Northern Territory': 'NT',
        'Western Australia': 'WA',
    }[state]

    return convert as ThreeLetterStates
}

export const StateLongToStateShort: Record<ValidAustralianStates, ValidAustralianShortStates> = {
    'New South Wales': 'NSW',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Victoria: 'VIC',
    Tasmania: 'TAS',
    'Australian Capital Territory': 'ACT',
    // 'Northern Territory': '',
    // 'Western Australia': '',
}
