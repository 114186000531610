import { Account, CarbonResult } from 'graphql/types'
import { CarbonCaretoriesType } from '.'
import {
    AverageHouseholdGas,
    AverageHouseholdKwh,
    StateLongToStateShort,
    StateToCities,
    ValidAustralianStates,
} from './data'
import { equals } from 'ramda'
import { commas, safeRound } from 'utils/helpers'

export default class CarbonHelpers {
    results: CarbonResult[] = []
    constructor(results: CarbonResult[]) {
        this.results = results
    }
    getValidResult = (result: CarbonResult[]) => {
        const validResults = result.filter((a) => a.Emissions !== '')
        return validResults
    }
    hasAddedAllCategories = (): boolean => {
        // all domains have results above zero
        return this.getValidResult(this.results).length === 5
    }
    numberOfCategoriesAdded = () => {
        const results = this.getValidResult(this.results)
        const length = results.length
        return length
    }


    getSourceForBlendedEmissions = () => {
        const { s, b, e, t, l } = this.getRawEmissionsForAllDomains()
        const blend = {
            super: s !== 'N/A' ? 'user' : 'average',
            banking: b !== 'N/A' ? 'user' : 'average',
            energy: e !== 'N/A' ? 'user' : 'average',
            transport: t !== 'N/A' ? 'user' : 'average',
            lifestyle: l !== 'N/A' ? 'user' : 'average',
        }
        return blend
    }
    hasCategoryResult = (domain: CarbonCaretoriesType): boolean => {
        // tell me if a category has a result
        if (this.getValidResult(this.results).filter((x) => x.Domain == domain).length > 0) {
            return true
        }
        return false
    }
    getDomainResult = (domain: CarbonCaretoriesType): CarbonResult[] => {
        // get me the result
        const domainResults = this.getValidResult(this.results).filter((x) => x.Domain == domain)
        if (domainResults.length === 0) return []
        const res = domainResults.sort((a, b) => Number(b.GeneratedAt) - Number(a.GeneratedAt))
        return [res[0]]
    }
    getRawEmissionsForAllDomains = () => {
        const s = this.getEmissionsForDomain('super')
        const b = this.getEmissionsForDomain('banking')
        const e = this.getEmissionsForDomain('energy')
        const t = this.getEmissionsForDomain('transport')
        const l = this.getEmissionsForDomain('lifestyle')

        return { s, b, e, t, l }
    }
    getEmissionsForDomain = (domain: CarbonCaretoriesType): string => {
        const domainResults = this.getValidResult(this.results)
            .filter((x) => x.Domain == domain)
            .sort((a, b) => Number(b.GeneratedAt) - Number(a.GeneratedAt))
        if (domainResults.length === 0) return 'N/A'
        return domainResults[0].Emissions ?? 'N/A'
    }
    // ENERGY
    getAverageHouseholdSizedKwh = (city: string, adults: number) => {
        if (city in AverageHouseholdKwh) {
            if (adults in AverageHouseholdKwh[city]) {
                return AverageHouseholdKwh[city][adults]
            }
        }
        return '-1'
    }
    getAverageHouseholdSizedKwhUsingState = (state: ValidAustralianStates, adults: number) => {
        if (state in StateToCities && Number.isInteger(adults) && adults > 0 && adults < 6) {
            const city = StateToCities[state]
            return this.getAverageHouseholdSizedKwh(city, adults)
        }
        return '-1'
    }
    // GAS
    getAverageHouseholdSizedGas = (state: string, adults: number) => {
        if (state in AverageHouseholdGas) {
            if (adults in AverageHouseholdGas[state]) {
                return AverageHouseholdGas[state][adults]
            }
        }
        return '-1'
    }
    getAverageHouseholdSizedGasUsingState = (state: ValidAustralianStates, adults: number) => {
        const shortState = StateLongToStateShort[state]
        if (
            shortState in AverageHouseholdGas &&
            Number.isInteger(adults) &&
            adults > 0 &&
            adults < 6
        ) {
            const yearly = this.getAverageHouseholdSizedGas(shortState, adults)
            const turnIntoQuartley = Number(yearly) / 4 // requested by Toby to be quarterly to match energy, advised that divide by 4 is accurate
            return turnIntoQuartley.toFixed(2)
        }
        return '-1'
    }

    sortOrder = ['super', 'banking', 'energy', 'transport', 'lifestyle']
    round = (emissionsNumber: string, dp?: number) => {
        return commas(Number(safeRound(Number(emissionsNumber), dp || 2)).toFixed(dp || 2))
    }
    chartColor = {
        super: '#EC7838',
        banking: '#C6393E',
        energy: '#F9BE44',
        transport: '#25A384',
        lifestyle: '#7BB9FE',
    }
}
