import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { HeroCard } from 'components/common/HeroCard'
import HomeCard from 'components/homecard'
import { AppContext } from 'model'
import YouVsOthers from 'components/you-vs-others'

const YourCarbon = () => {
    const app = React.useContext(AppContext)
    const emissions = app.carbon.results.reduce((a,b) => a + Number(b.Emissions), 0)

    return (
        <Box>
            <Box rounded="md" >
                <Text
                    lineHeight="1.1"
                    fontWeight={500}
                    fontSize={['24px', '24px', '28px']}
                    color="rgba(0, 0, 0, 1)"
                    padding="10px 12px"
                >
                    Your Carbon Impact
                </Text>
                <YouVsOthers emissions={emissions} />
            </Box>
        </Box>
    )
}

export default YourCarbon
