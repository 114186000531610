import { gql } from '@apollo/client'

export default gql`
    query knowledgeBaseCollection($preview: Boolean) {
        knowledgeBaseCollection(preview: $preview) {
            items {
                title
                id
                category
                content {
                    json
                }
            }
        }
    }
`