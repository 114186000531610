import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import AdminToolbar from './toolbar'
import AdminRoutes from './Routes'
import { AppContext } from 'model'
import ErrorScreen from 'pages/errorScreen'

const Admin = () => {
    const app = React.useContext(AppContext)
    if (app.partner.IsAdmin != "true") return <ErrorScreen message="This account does not have access to the admin area" backButton={true} />
    return (
        <Box>
            <Center>
                <div style={{backgroundColor: "white", border: "1px solid #ccc", borderRadius: "2px",width: "70%"}}>
                    <div style={{padding: "10px 15px"}}>Admin Area</div>
                    <AdminToolbar />
                    <AdminRoutes />
                </div>
            </Center>
        </Box>
    )
}



export default Admin
