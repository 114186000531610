import * as React from 'react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { AppContext } from 'model'

const AdminDashboard = () => {
    const app = React.useContext(AppContext)
    if (!app.partner.IsAdmin) return <div>Unable to access admin area</div>
    return (
        <div style={{margin: "10px 15px", padding: "10px 15px", border: "1px solid #333"}}>Reporting</div>
    )
}

export default AdminDashboard
