import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
    Grid,
    GridItem,
} from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { HeroCard } from 'components/common/HeroCard'
import HomeCard from 'components/homecard'
import YourCarbon from './yourCarbon'
import OurImpact from './ourImpact'
import Notifications from './notifications'
import OurClimateIncentives from './outClimateIncentives'

const Dashboard = () => {
    const { user } = useAuth0()
    return (
        <Box width={`100%`}>
            <Grid
                h='200px'
                templateRows='repeat(3, 1fr)'
                templateColumns='repeat(3, 1fr)'
                gap={4}
            >
                <GridItem >
                    <YourCarbon />
                </GridItem>
                <GridItem>
                    <OurImpact />
                </GridItem>
                <GridItem rowSpan={3} bg='papayawhip'>
                    <Notifications />
                </GridItem>
                <GridItem rowSpan={2}  colSpan={2} bg='papayawhip'>
                    <OurClimateIncentives />
                </GridItem>
            </Grid>
        </Box>
    )
}



export default Dashboard
