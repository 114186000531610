import { AppContext } from 'model'
import * as React from 'react'

const AcaciaIDP = () => {
    const app = React.useContext(AppContext)
    
    return (
        <div>
            <p>
                This page contains information about setting up the Acacia IDP for login processes 
            </p>
            <p>
                Steps that need to be completed
            </p>
            <ol>
                <li>Contact Acacia and send them the callback domain to be white listed on the Acacia login screen</li>
                <li>Redirect all authenticated pages to the Acacia login screen according to the desired 0Auth login flow</li>
            </ol>
        </div>
    )
}

export default AcaciaIDP
