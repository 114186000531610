import b from 'bss'

export const container = b`
    background-color: white;
    border-radius: 10;
    width: 100%;
    padding: 20;
`.class

export const header = b`
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`.class

export const title = b`
    font-size: 14;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 12;
    font-weight: 500;
`.class

export const type = b`
    padding: 8;
    border: 1 #EBEBEB solid;
    border-radius: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
`.class

export const typeText = b`
    font-size: 11;
    color: rgba(0, 0, 0, 0.85);
    padding-right: 6;
    font-weight: 500;
`.class

export const main = b`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`.class

export const number = b`
    font-size: 40;
    letter-spacing: -0.25;
    font-weight: 500;
`.class

export const description = b`
    display: flex;
    flex-direction: row;
    align-items: center;
`.class

export const descriptionText = b`
    font-size: 20;
    color: #939393;
    padding-bottom: 5;
    padding-left: 8;
    font-weight: 500;
`.class

export const pill = b`
    width: 20;
    height: 8;
    background-color: #FCBD1A;
    border-radius: 99;
    margin-left: 8;
`.class

export const subheading = b`
    font-size: 12;
    color: #939393;
    padding-top: 2;
`.class

export const gradient = b`
    width: 100%;
    height: 32;
    background: linear-gradient(to right, #FF8211, #FCBD1A, #46C252, #01B7F8);
`.class

export const markers = b`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 8;
    padding-top: 6; 
    justify-content: space-between;
`.class

export const innerMarkers = b`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
`.class

export const marker = b`
    font-size: 12;
    color: #939393;
`.class

export const poles = b`
    display: flex;
    flex-direction: row;
`.class

export const pole = b`
    width: 1;
    height: 12;
    background-color: #EBEBEB;
`.class

export const shortPole = b`
    width: 1;
    height: 6;
    background-color: #EBEBEB;
`.class

export const label = b`
    border: 1px #EBEBEB solid;
    border-radius: 99;
    padding: 6px 3px;
    flex-direction: row;
    display: flex;
    align-self: flex-start;
    align-items: center;
`.class

export const labelText = b`
    font-size: 10;
`.class

export const dot = b`
    width: 4;
    height: 4;
    border-radius: 99;
    margin-right: 6;
    background-color: #ff8211;
    background: linear-gradient(to left top, #46C252, #01B7F8);
`.class

export const row = b`
    flex-direction: row;
    display: flex;
    margin-top: 12px;
`.class

export const youPill = b`
    background-color: black;
    border-radius: 99;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 27;
    justify-content: center;
    z-index: 99;
`.class

export const youText = b`
    color: white;
    font-size: 12;
    font-weight: 500;
`.class

export const youPole = b`
    width: 1;
    height: 60;
    background-color: black;
    align-self: center;
`.class
