import { Avatar, Box, Flex, HStack, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

interface HeroCardProps {
    mainText: string
    secondaryText?: string
    otherText?: string
    imageUrl?: string
}

export const HeroCard = (props: HeroCardProps) => {

    return (
        <Flex >
            { props.imageUrl && (
                <VStack gap="0">
                    <Avatar
                        size="1xl"
                        src={props.imageUrl}
                        boxSize={"40px"}
                    />
                </VStack>
            )}
        </Flex>
    )
}