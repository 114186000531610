// @ts-nocheck
import React from 'react'
import {
    ChakraProvider,
    ColorModeScript
} from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import Spinner from './components/common/Spinner'
import Routes from './Routes'
import theme from './theme'
import Header from './components/header'
import { AppContext, useAppModel } from 'model'
import  ErrorScreen from 'pages/errorScreen'

function App() {
    const appModel = useAppModel()
    if (appModel.error) return <ErrorScreen message={appModel.error} />
    if (!appModel.partner) return <Spinner />
    return (
        <div className="App">
            <ChakraProvider theme={theme} resetCSS>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <AppContext.Provider value={appModel}>
                    <BrowserRouter>
                        <Header>
                            <Routes />
                        </Header>
                    </BrowserRouter>
                </AppContext.Provider>
            </ChakraProvider>
        </div>
    )
}

export default App
