const url = process.env.ENVIRONMENT === 'prod' || window.location.href.indexOf('prod') > -1 
                ? 'https://67hug2hlzrgbjckgebqfxqh5iy.appsync-api.ap-southeast-2.amazonaws.com/graphql' :
            process.env.ENVIRONMENT === 'wattle' || window.location.href.indexOf('wattle') > -1 
                ? 'https://jbclfvvdmfbs5af4g75mvjb6f4.appsync-api.ap-southeast-2.amazonaws.com/graphql' :
            process.env.ENVIRONMENT === 'chris' || window.location.href.indexOf('chris') > -1 
                ? 'https://nall2ffsqrervixqnjkxhe5654.appsync-api.ap-southeast-2.amazonaws.com/graphql' :
            'https://o6s7qnh24bbsjiqytpnjywkpy4.appsync-api.ap-southeast-2.amazonaws.com/graphql'

const appsyncConfig = {
    graphqlEndpoint: url,
    region: 'ap-southeast-2',
    authenticationType: 'OPENID_CONNECT',
}
if (process.env.REACT_APP_APPSYNC) {
    console.log('DEV MODE!!')
    appsyncConfig.graphqlEndpoint = process.env.REACT_APP_APPSYNC
}

export default appsyncConfig
