import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import Routes from '../Routes'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from 'model'

const Article = () => {
    const { id } = useParams<any>()
    const app = React.useContext(AppContext)
    const allArticles = app.contentful.all
    if (!allArticles) return <></>
    const article = allArticles.find(a => a.id == id)
    if (!article) throw new Error("unable to locate article")
    return (
        <Box>
            <Center>
                <Link to={`/knowledgebase/category/${article.category}`}><div>Back</div></Link>
                <div style={{backgroundColor: "white", border: "1px solid #ccc", borderRadius: "2px",width: "70%"}}>
                    <h1 style={{padding: "10px 15px"}}>{article.title}</h1>
                    {app.contentful.GetRichText(article.id)}
                </div>
            </Center>
        </Box>
    )
}

export default Article
