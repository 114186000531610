import { AppContext } from 'model'
import * as React from 'react'

const AdminDocs = () => {
    const app = React.useContext(AppContext)
    return (
        <div style={{margin: "10px 15px", padding: "10px 15px", border: "1px solid #333"}}>API Reference guide</div>
    )
}

export default AdminDocs
