import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Box,
    Text,
    Card,
    CardHeader,
    CardBody,
    GridItem,
    Grid,
} from '@chakra-ui/react'
import { AppContext } from 'model'

const OurClimateIncentives = () => {
    const app = React.useContext(AppContext)

    const testImpact = {
        AverageEmissions: 200,
        CarbonSaved: 52783,
        TotalSavedFromSwitching: 30928
    }
    const impact = testImpact
    // const impact = app.partner.Impact

    return (
        <Box>
            <Card>
                <CardHeader>Our Climate Incentives</CardHeader>
                <CardBody>
                    <Grid
                        templateRows='repeat(1, 1fr)'
                        templateColumns='repeat(3, 1fr)'
                        gap={4}
                    >
                        <GridItem style={{borderRight: "1px solid grey", paddingRight: "18px"}}>
                            <Text size="20px">Do this thing</Text>
                        </GridItem>
                        <GridItem style={{borderRight: "1px solid grey", paddingRight: "18px"}}>
                            <Text size="20px">Or this</Text>
                        </GridItem>
                        <GridItem>
                            <Text size="20px">Or this</Text>
                        </GridItem>
                    </Grid>
                    
                </CardBody>
            </Card>
            
        </Box>
    )
}

export default OurClimateIncentives
