import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'
import {
    Avatar,
    Box,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import Routes from '../Routes'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from 'model'

const Category = () => {
    const { id } = useParams<any>()
    const app = React.useContext(AppContext)
    const allArticles = app.contentful.all
    if (!allArticles) return <></>
    const articles = allArticles.filter(a => a.category == id)
    return (
        <Box>
            <Center>
                <div style={{backgroundColor: "white", border: "1px solid #ccc", borderRadius: "2px",width: "70%"}}>
                    <h1 style={{padding: "10px 15px"}}>{id}</h1>
                    { articles.map(a => {
                        return (
                            <Link to={`/knowledgebase/article/${a.id}`}>
                                <div style={{
                                    borderRadius: "1px",
                                    border: "1px solid #333",
                                    padding: "10px 6px",
                                    margin: "10px 6px",
                                    fontSize: "16px"
                                }}>{a.title}</div>
                            </Link>
                        )
                    })}
                </div>
            </Center>
        </Box>
    )
}

export default Category
