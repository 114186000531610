import { Button, Input, Radio, RadioGroup, Spinner, Stack } from '@chakra-ui/react'
import { AppContext } from 'model'
import * as React from 'react'
import OidcConfig from './oidcConfig'
import AcaciaIDP from './acaciaIdp'
import ApiKeys from './apiKeys'

const AdminConfig = () => {
    const app = React.useContext(AppContext)
    const [authMethod, setAuthMethod] = React.useState<string>("")
    const [oidcEndpoint, setOidcEndpoint] = React.useState<string>("")
    const [oidcTestToken, setOidcTestToken] = React.useState<string>("")

    React.useEffect(() => {
        if (app.partner.Partner.AdminConfig && app.partner.Partner.AdminConfig.ApiAuthenticationMethod) 
            setAuthMethod(app.partner.Partner.AdminConfig.ApiAuthenticationMethod)
        if (app.partner.Partner.AdminConfig && app.partner.Partner.AdminConfig.OidcUrl) 
            setOidcEndpoint(app.partner.Partner.AdminConfig.ApiAuthenticationMethod)
    }, [])
    
    const saveConfig = async () => {

    }
    return (
        <div>
            <div style={{margin: "10px 15px", padding: "10px 15px", border: "1px solid #333"}}>
                <div style={{margin: "10px 15px", padding: "10px 15px", border: "1px solid #333"}}>
                    <div>Authentication Method</div>
                    <div>This is the process that the remote systems will use to authenticate with the various Acacia APIs</div>
                    <RadioGroup onChange={setAuthMethod} value={authMethod}>
                        <Stack direction='column'>
                            <Radio value=''>None</Radio>
                            <Radio value='acacia'>Acacia IDP</Radio>
                            <Radio value='oidc'>Custom OIDC</Radio>
                            <Radio value='key'>API key</Radio>
                        </Stack>
                    </RadioGroup>
                    {authMethod == "acacia" && <div>This process will utilise the Acacia login page and tokens to authenticate the API calls</div> }
                    {authMethod == "oidc" && <div>This process will your own custom OIDC compliant IDP login process and JWKS endpoint to validate 0auth bearer tokens that will be attached the to the calls to the Acacia APIs. You must input the URL of your JWKS endpoint below</div> }
                    {authMethod == "key" && <div>An API key will be attached to the header of the various API calls to complete API authenication. This process will only be appropriate for a server to server solution design.</div> }
                </div>
                { authMethod == "acacia" && <AcaciaIDP /> }
                { authMethod == "oidc" && <OidcConfig 
                    setOidcEndpoint={setOidcEndpoint}
                    oidcEndpoint={oidcEndpoint}
                    setOidcTestToken={setOidcTestToken}
                    oidcTestToken={oidcTestToken}
                /> }
                { authMethod == "key" && <ApiKeys /> }
                <div>
                    <Button onClick={() => saveConfig()}>Save</Button>
                </div>
            </div>
            
        </div>
    )
}

export default AdminConfig
