export const categories = [
    {
        title: "Energy",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Wind_turbines_in_southern_California_2016.jpg/660px-Wind_turbines_in_southern_California_2016.jpg"
    },
    {
        title: "Banking",
        image: "https://imageio.forbes.com/specials-images/imageserve/64b422292e66e94da1248079/Bank-Collapse/0x0.jpg?format=jpg&crop=4380,2595,x1299,y228,safe&width=960"
    },
    {
        title: "Lifestyle",
        image: "https://www.credihealth.com/media/j6mepa9c9b4fyrcycvveiimhtn7w/healthy-young-woman-warming-up-outdoors-workout-before-training"
    },
    {
        title: "Transport",
        image: "https://www.easyhaul.com/blog/wp-content/uploads/2021/07/Blog-EasyHaul-Modes-of-Transport-Title-1.png"
    },
]