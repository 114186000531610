import * as React from 'react'
import NotPartnerError from './notPartner'
import { Box, Button } from '@chakra-ui/react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { getErrorMessage } from './errorMessage'

type Props = {
    message: string
    logoutButton?: boolean
    backButton?: boolean
}

const ErrorScreen = ({ message, logoutButton, backButton }: Props) => {
    const { logout } = useAuth0()
    return (
        <div style={{textAlign: "center",border: "1px solid grey", borderRadius: "5px", margin: "10px 20px", padding: "20px 60px", backgroundColor: "lightgrey"}}>
            <div style={{ margin: "10px 0 50px"}}>
                <h2>Oh no! Looks like something has gone wrong!</h2>
            </div>
            <div style={{ margin: "10px 0 50px"}}>
                <img src='https://png.pngtree.com/png-vector/20220519/ourmid/pngtree-sad-dog-cartoon-illustration-doggy-canine-nose-vector-png-image_13548432.png' />
            </div>
            <div>
                {getErrorMessage(message)}
            </div>
            <div>
                {backButton && <Button onClick={() => history.back()}>Go Back</Button> }
                {logoutButton && <Button onClick={() => logout()}>Logout</Button> }
            </div>
        </div>
    )
}

export default ErrorScreen